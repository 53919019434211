import { createSlice, PayloadAction } from "@reduxjs/toolkit";

//TODO: add error types and integrate into catch() statements

export interface ErrorInterface {
  fatal: boolean,
  Message: string,
  status: number,
  errorType: string,
  ExceptionMessage: string;
}

interface ErrorState {
  error: ErrorInterface;
}

const initialState: ErrorState = {
  error: {
    fatal: false,
    Message: "",
    status: 0,
    errorType: "",
    ExceptionMessage: "",

  },
};
const errorSlice = createSlice({
  name: "error",
  initialState,
  reducers: {
    updateError(state, action: PayloadAction<ErrorInterface>) {
      return Object.assign({}, state, { error: action.payload });
    },
  },
});

export const errorActions = errorSlice.actions;

export function registerError(dispatch: any, e: any, type: string, fatal: boolean) {
  if (e) {
    console.error(e);
    dispatch(errorActions.updateError({ Message: e.statusText, status: e.status, errorType: type, fatal: fatal, ExceptionMessage: e.data?.Message ?? e.data?.ExceptionMessage }));
  }
}

export default errorSlice;
