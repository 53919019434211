import { configureStore } from "@reduxjs/toolkit";
import errorSlice from "./error-slice";
import menuSlice from "./menu-slice";
import studentSlice from "./student-slice";

import tokenSlice from "./token-slice";
import userSlice from "./user-slice";

const store = configureStore({
  reducer: {
    user: userSlice.reducer,
    menu: menuSlice.reducer,
    token: tokenSlice.reducer,
    student: studentSlice.reducer,
    error: errorSlice.reducer
  },
});

export default store;

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
