import axios, { AxiosResponse, AxiosError, AxiosRequestConfig, AxiosInstance } from "axios";
import { msalInstance } from "..";
import { addQueryParamIfValue } from "../Reusable";
import { ServerErrorResponse, isServerErrorResponse } from "../adapters/ApiSchema";
import { AuthenticationResult } from "@azure/msal-browser";

export type UpsertRequestConfig<T> = {
    headers?: any
    method?: "POST" | "PUT" | "PATCH"
    data: T
}

export interface IApiRequestHandler {
    getHostName(): string
    getOrDelete<T>(
        url: string,
        params: AxiosRequestConfig
    ): Promise<T | ServerErrorResponse>

    updateMethod<TArg, T>(
        url: string,
        params: UpsertRequestConfig<TArg>
    ): Promise<T | ServerErrorResponse>
}

export const getSilentToken = (config: any) => {
    let request = {
        ...config,
        account: msalInstance.getAllAccounts()[0],
    };

    return new Promise(function (resolve, reject) {
        msalInstance.acquireTokenSilent(request)
            .then((tokenResponse) => {
                // apiToken = tokenResponse;
                resolve(tokenResponse.accessToken);
            }).catch(_ => msalInstance.acquireTokenRedirect(request));
    })
}

const extractData = <T>(resp: AxiosResponse<T>): T | ServerErrorResponse => {
    if (resp.status === 200 && !isServerErrorResponse(resp) && !isServerErrorResponse(resp.data)) {
        return resp.data
    }
    console.error("Unable to extract data", resp.status, resp.statusText, resp.data);

    let respErr: ServerErrorResponse | undefined = isServerErrorResponse(resp.data) ? resp.data as ServerErrorResponse : undefined;
    if (!!respErr) return respErr;

    console.log(resp.status);
    console.log(resp.statusText);
    console.error(resp.data);
    // fall back
    return { Message: "Unknown response error", StatusCode: resp.status, StatusMessage: (resp?.data as unknown as ServerErrorResponse)?.StatusMessage || resp.statusText }
}

const catchAxios = (error: AxiosError): ServerErrorResponse => {

    console.log(error.response || error);

    let resp = error.response
    let data = resp?.data as ServerErrorResponse | undefined;
    if (!!data) {
        if (!data.StatusCode) data.StatusCode = resp?.status;
        return data;
    }
    return { Message: "Unknown server error", StatusCode: error.response?.status, MessageDetail: JSON.stringify(resp?.data) }
}

export default class ApiRequestHandler implements IApiRequestHandler {
    private handler: AxiosInstance
    private apiHostName: string

    protected createAxiosInstance(): AxiosInstance {

        return axios.create({
            headers: {
                Accept: "application/json;odata=verbose",
                'Content-Type': "application/json; charset=UTF-8",
                'X-app-location': window.location.origin + window.location.pathname
            }
        })
    }

    constructor(apiConfig: any) {
        this.handler = this.createAxiosInstance();

        /**
        * Inject Authorization Request Header for all requests.
        */
        // TODO: fix token handling
        // HACK: work around for not checking token expiration
        this.handler.interceptors.request.use(
            async request => {
                //request.params = {timestamp: Date.now()};
                let token = await getSilentToken(apiConfig);
                request.headers['Authorization'] = "Bearer " + token;
                return request
            },
            error => {
                return Promise.reject(error);
            }
        )
        this.apiHostName = apiConfig.hostname;
    }
    getHostName(): string {
        return this.apiHostName;
    }

    async getOrDelete<T>(url: string, arc: AxiosRequestConfig): Promise<T | ServerErrorResponse> {
        let method = arc.method
        if (method === undefined) {
            method = "GET"
        }
        // move params into query string
        if (!!arc.params) {
            // if (!arc.params.err) // log if we see any other things using the params in this path
            //     console.warn('getOrDelete params', arc.params);
            Object.keys(arc.params).map(k => {
                url = addQueryParamIfValue(url, k, arc.params[k])
                return undefined;
            });
        }

        switch (method.toUpperCase()) {
            case "DELETE": {
                return this.handler.delete(url, arc.headers).then(x => extractData<T>(x)).catch(catchAxios);
            }
            case "GET": {
                return this.handler.get(url, arc.headers).then(x => extractData<T>(x)).catch(catchAxios);
            }
            default: {
                throw new Error('Invalid Method Type: Must use Get or Delete')
            }
        }
    }

    async updateMethod<TArg, T>(url: string, params: UpsertRequestConfig<TArg>): Promise<T | ServerErrorResponse> {
        let method = params.method || "POST";
        switch (method.toUpperCase()) {
            case "PATCH": {
                return this.handler.patch(url, params.data, params.headers).then(x => extractData<T>(x)).catch(catchAxios);
            }
            case "PUT": {
                return this.handler.put(url, params.data, params.headers).then(x => extractData<T>(x)).catch(catchAxios);
            }
            case "POST": {
                return this.handler.post(url, params.data, params.headers).then(x => extractData<T>(x)).catch(catchAxios);
            }
            default: {
                throw new Error('Invalid Method Type: Must use POST, PUT, or PATCH')
            }
        }
    }
}