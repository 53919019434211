import defaultStore from "../util/Globale";
import { IApiRequestHandler } from "../util/APIRequestHandler";
import { MenuItem } from "../store/menu-slice";
import { ServerErrorResponse } from "../adapters/ApiSchema";
import { tryGetUpnOverride } from "./DCPS";
import { addQueryParams } from "../Reusable";


export interface INavigationService {
  getUserMenu(): Promise<UserMenuResponseData | ServerErrorResponse>
}

// from mainActionMenu?
export type UserMenuResponseData = {
  Items: MenuItem[]
}

// optionally bypassCache=true
export default class NavigationService implements INavigationService {
  private navBarApiClient: IApiRequestHandler
  _upn: string | undefined = undefined;

  constructor(apiClient: IApiRequestHandler) {
    const overrideUpn = tryGetUpnOverride();
    if (!!overrideUpn) {
      this._upn = overrideUpn as string;
    }
    this.navBarApiClient = apiClient;
  }

  buildUrl(relPath: string, queryParams?: { key: string, value: string }[]): string {
    if (!!this._upn || (queryParams && queryParams.length > 0)) {
      var qp = !!this._upn ? [{ key: "upn", value: this._upn }, ... (queryParams ?? [])] : queryParams ?? [];
      return addQueryParams(this.navBarApiClient.getHostName() + relPath, qp);
    }

    return this.navBarApiClient.getHostName() + relPath;
  }

  async getUserMenu(): Promise<UserMenuResponseData | ServerErrorResponse> {
    let _url = this.buildUrl(defaultStore.webAPIEndPointUserMenuItems);

    return await this.navBarApiClient.getOrDelete<UserMenuResponseData>(_url, {});
  }

}