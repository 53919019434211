// choosing to make key string only

export const setToLS = <T>(key: string, value: T) => {
  window.localStorage.setItem(key, JSON.stringify(value));
};

export const getFromLS = <T>(key: string): T | undefined => {
  const value = window.localStorage.getItem(key);

  if (value) {
    return JSON.parse(value) as T;
  }
};

export const setToSS = <T>(key: string, value: T) => {
  window.sessionStorage.setItem(key, JSON.stringify(value));
};

export const getFromSS = <T>(key: string): T | undefined => {
  const value = window.sessionStorage.getItem(key);

  if (value) {
    return JSON.parse(value) as T;
  }
};

export function removeLSItems(regEx: any) {
  Object.keys(localStorage).forEach((key) => {
    if (regEx.test(key)) {
      localStorage.removeItem(key);
    }
  });
}

export function removeSSItems(regEx: any) {
  Object.keys(sessionStorage).forEach((key) => {
    if (regEx.test(key)) {
      sessionStorage.removeItem(key);
    }
  });
}
