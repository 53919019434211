import { IObservable, Observable, ObservableValue, Unsubscriber } from "./BObservable";
import { ServerErrorResponse, isServerErrorResponse } from "../adapters/ApiSchema";
import { System } from "../Reusable";

type ServerResponse<T> = T | ServerErrorResponse
export class RemoteDataWrapper<T> implements IObservable<ServerResponse<T>> {

    private readonly _fetcher: System.Func<Promise<ServerResponse<T>>>;
    private readonly _obs: Observable<ServerResponse<T>>;

    private _inFlight = false;
    private _initialized;
    private _value: ServerResponse<T> | undefined;


    private setValue(x: ServerResponse<T>) {
        this._value = x;
        this._obs.Next(x);
        this._inFlight = false;
        this._initialized = true;
    }

    public get value() { return this._value; }

    constructor(defaultValue: T | undefined, fetcher: System.Func<Promise<T | ServerErrorResponse>>) {
        this._value = defaultValue;
        this._fetcher = fetcher;
        this._obs = new Observable();
        this._initialized = !!defaultValue;
    }

    public async Initialize() {
        if (!this._initialized && !this._inFlight) {
            console.warn("initializing");
            this._inFlight = true;
            this.setValue(await this._fetcher());
        }
    }

    public async Refresh() {
        console.log('refresh called');
        if (!this._inFlight) {
            this._inFlight = true;
            this.setValue(await this._fetcher());
        }
    }
    public Subscribe(action: WeakRef<System.Action1<ServerResponse<T>>>): Unsubscriber {
        return this._obs.Subscribe(action);
    }
}