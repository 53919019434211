
import { TextField } from '@mui/material';
import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';

import { profileService } from "../util/Services";
import { PrimaryButton } from '@fluentui/react';
import { debounce } from '../Reusable';
import { isServerErrorResponse } from '../adapters/ApiSchema';
import { validateEmail } from '../services/Common';

export const RenameComponent = ({ username }: { username: string }) => {
    const [inFlight, setInFlight] = useState<boolean>(false);
    const [currentUsername, setCurrentUsername] = useState<string>(username);
    const [status, setStatus] = useState<string>("");
    const [nextUsername, setNextUsername] = useState<string>("");
    const [usernameMsg, setUsernameMsg] = useState<string | undefined>(undefined);
    const [usernameError, setUsernameError] = useState<boolean>(false);

    let submitRename = () => {
        if (inFlight) return;
        setInFlight(true);
        setStatus("Attempting rename");
        const oldUsername = currentUsername;
        profileService.postEmailRename(nextUsername).then(result => {
            if (!isServerErrorResponse(result)) {
                setCurrentUsername(nextUsername);
                setStatus("Rename completed (" + oldUsername + ") -> (" + nextUsername + ")")
                // do not turn off in-flight, no reason to let them run it again
                return;
            } else {
                setStatus("Failed")
            }
            setInFlight(false);
        });
    };

    let onNeedsVerify = (x: string) => {
        let localErrorMessagePrefix = "Client: ";
        if (!x || x === "" || /^\s+$/.test(x)) {
            setUsernameError(true);
            setUsernameMsg(localErrorMessagePrefix + "No email address provided");
            return;
        }
        let validationResult = validateEmail(x);
        if (validationResult.state !== "ok") {
            setUsernameError(true);
            setUsernameMsg(localErrorMessagePrefix + validationResult.error);
            return;
        }

        profileService.verifyAvailableEmail(x).then(resp => {
            if (!isServerErrorResponse(resp) && resp.Accepted === true) {
                setUsernameError(false);
                setUsernameMsg(resp.Email + ': ' + resp.Message);
            }
            else {
                console.error(resp);
                setUsernameMsg(resp?.Message);
                setUsernameError(true);
            }
        });
    };

    const debouncedOnAccept = useMemo(() => debounce(onNeedsVerify, 500), []);

    useEffect(() => {
        debouncedOnAccept(nextUsername);
    }, [nextUsername, debouncedOnAccept]);

    let onDesiredChanged = async (value: string) => {
        setNextUsername(value);
    }

    return <div>
        <h2>Email address update</h2>
        <div>
            <div>
                <TextField
                    sx={{ width: 350 }}
                    id="disabled-input-with-sx"
                    disabled
                    value={currentUsername || username}
                    label={"Existing Username"}
                    variant="standard"
                />
            </div>
            <div>
                <TextField
                    sx={{ width: 350 }}
                    id="desired-input-with-sx"
                    autoFocus
                    onChange={e => onDesiredChanged(e.target.value)}
                    defaultValue={nextUsername}
                    label={"Desired Username"}
                    variant="standard"
                />
            </div>
            <div>{status}</div>
            <div
                className={
                    usernameError ? "ErrorMessage" : ""
                }
            > {usernameMsg}
            </div>
            <PrimaryButton
                className="fluentUI primaryButton"
                onClick={submitRename}
                disabled={usernameError || inFlight}
                style={{ padding: 5, margin: 5 }}
                color="primary"
            >
                Submit
            </PrimaryButton>
        </div>


    </div>
}