import React, {
  useState,
  useEffect,
  Dispatch,
  SetStateAction,
  useCallback,
} from "react";
import { useDispatch } from "react-redux";

import { formatDate } from "../../services/Common";
import useEventListener from "../../util/useEventListener";

import { styled } from '@mui/material/styles';
import { Card, CardHeader, CardContent, CardActions, Collapse, Avatar, Typography, Skeleton, CircularProgress, Popover, useMediaQuery } from '@mui/material';
import { Share as ShareIcon, ExpandMore as ExpandMoreIcon, ContentCopy as ContentCopyIcon, Check as CheckIcon } from '@mui/icons-material';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import { red, grey } from '@mui/material/colors';
import { useTheme } from "@mui/material/styles";
// options google charts, chartJs, rGraph, d3.js
import { Chart as ChartJS, ArcElement, Tooltip, Legend, CategoryScale, LinearScale } from "chart.js";
import { Bar, Doughnut, Pie } from "react-chartjs-2";

import { registerError } from "../../store/error-slice";

import LazyLoad from 'react-lazyload';

import { newDcpsService } from "../../util/Services";
import { ActivityFeedItemProps } from "../../services/DCPS";
import { isServerErrorResponse } from "../../adapters/ApiSchema";
import { withScrollingTarget } from "../../adapters/Eventing";


const THUMB_HEIGHT = "100%";
const MAX_HEIGHT = 600;
export const MAX_WIDTH = 650;

const PREFIX = "CDS";

const classes = {
  feed: `${PREFIX}-feed`,
  shimmer: `${PREFIX}-shimmer`,
  hover: `${PREFIX}-hover`,
  loadIcon: `${PREFIX}-loadIcon`,
  share: `${PREFIX}-share`,
  sharePostCopy: `${PREFIX}-sharePostCopy`,
  container: `${PREFIX}-container`,
  containerShimmer: `${PREFIX}-containerShimmer`,
  blur: `${PREFIX}-blur`
};

const Root = styled('div')(({ theme }) => ({
  [`& .${classes.feed}`]: {
    display: "flex",
    flexDirection: "column",
    width: MAX_WIDTH,
    [theme.breakpoints.down('md')]: {
      width: 'auto',
    }
  },
  [`& .${classes.shimmer}`]: {
    top: 70
  },
  [`& .${classes.hover}`]: {
    cursor: "pointer",
  },
  [`& .${classes.loadIcon}`]: {
    alignSelf: "center",
    marginBottom: 20,
  },

  [`& .${classes.share}`]: {
    display: 'flex',
    width: 175,
    backgroundColor: 'transparent',
    transition: theme.transitions.create(['color', 'background-color', 'padding-left'], {
      duration: theme.transitions.duration.complex,
    }),
    cursor: "pointer",
  },

  [`& .${classes.sharePostCopy}`]: {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.primary.contrastText,
    alignItems: 'center',
    paddingLeft: 10,
    cursor: "default",
    pointerEvents: "none",
  },
  [`& .${classes.container}`]: {
    marginBottom: 20,
    boxShadow: "1px 1px 10px #dbdbdb",
  },
  [`& .${classes.containerShimmer}`]: {
    width: '85vw',
    maxWidth: MAX_WIDTH,
  },
  [`& .${classes.blur}`]: {
    position: 'relative',
    width: '100%',
    height: '80px',
    marginTop: '-60px',
    backgroundImage: 'linear-gradient(to bottom, rgba(255,255,255,0) 0%, white 80%, white 100%)',
  }
}));

/*
    Displayed while loading
*/
const Shimmer = () => {
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <Card raised={true} className={`${classes.container} ${classes.containerShimmer}`}>
      <CardHeader //TODO get avatar from backend
        avatar={<Skeleton animation="wave" variant="circular" width={40} height={40} />}
        action={null}
        title={<Skeleton animation="wave" height={10} width="80%" style={{ marginBottom: 6 }} />}
        subheader={<Skeleton animation="wave" height={10} width="40%" />}
        min-width={'90vw'} // Prevent card from shrinking too much
      />
      <Skeleton height={sm ? 300 : 500} animation="wave" variant="rectangular" />
      <CardContent>
        <React.Fragment>
          <Skeleton animation="wave" height={10} style={{ marginBottom: 6 }} />
          <Skeleton animation="wave" height={10} width="80%" />
        </React.Fragment>
      </CardContent>
    </Card>
  );
}

const END_OF_FEED = "endOFfeed";
export const SS_PREF = "CDS";

const Feed = () => {
  const [items, setItems] = useState<any[]>([]);
  const [nextUrl, setNextUrl] = useState<string>("");
  const [initiallyLoading, setInitiallyLoading] = useState<boolean>(true)
  const [loading, setLoading] = useState<boolean>(false);
  const dummyItems = new Array(9).fill(0);

  const dispatch = useDispatch();

  // Reset scroll
  useEffect(() => {
    window.scrollTo(0, 0);
    //whoAmI(newToken).then(res=>console.log(res));
    return () => window.scrollTo(0, 0);
  }, []);

  const loadPosts = useCallback((nextUrl: string, initial: boolean) => {
    console.log('loading posts', nextUrl)
    if (nextUrl !== END_OF_FEED) {
      initial ? setInitiallyLoading(true) : setLoading(true);
      newDcpsService.getActivityFeedItems(nextUrl).then((res) => {
        // console.log("Posts loaded with result: ", result);
        if (!isServerErrorResponse(res)) {
          if (res.Items.length === 0 || !res.Items) {
            setNextUrl(END_OF_FEED);
          } else if (nextUrl !== res.NextURL) {
            // in case token refreshes, triggering a duplicate load
            // possible memory leak
            setNextUrl(res.NextURL);
            updateItems(res, items, setItems);
          }
          initial ? setInitiallyLoading(false) : setLoading(false);
        } else {
          console.log(res);
          registerError(dispatch, res, "CDS", false);
        }
      });
    }
  }, [dispatch]);

  useEffect(() => {
    // If it's our initial load without cache, load with shimmer
    loadPosts(nextUrl, true);
  }, []);

  // Load again when we scroll to the bottom of the page (DESKTOP)
  useEventListener(window, 'scroll', withScrollingTarget((element: unknown) => {
    if (element) {
      const windowHeight = "innerHeight" in window ? window.innerHeight : document.documentElement.offsetHeight;
      const body = document.body;
      const html = document.documentElement;
      const docHeight = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight);
      const windowBottom = windowHeight + Math.ceil(window.scrollY + 1);
      if (windowBottom >= docHeight) {
        if (!loading && !initiallyLoading) {
          loadPosts(nextUrl, false);
          console.log("bottom");
        }
      }
    }
  }), nextUrl);

  return (<>

    {initiallyLoading ?
      <Root data-file="CDS" data-method="Feed" data-state="initiallyLoading">
        <div className={classes.shimmer}>
          {dummyItems.map((_, index) => (
            <Shimmer key={index} />
          ))}
        </div>
      </Root>
      :
      <Root data-file="CDS" data-method="Feed">
        <div className={classes.feed}>
          {items.map((item, index) => (
            <PostItem key={index} {...item} />
          ))}
          {loading && <CircularProgress className={classes.loadIcon} />}
          {nextUrl === END_OF_FEED && <Typography align='center' variant='body2' style={{ marginBottom: 10 }} color={grey[400]}>End of Feed</Typography>}
        </div>
      </Root>
    }
  </>)

}

const updateItems = (response: { Items: ActivityFeedItemProps[] }, items: ({ id: string } & PostItemProps)[], setItems: Dispatch<SetStateAction<PostItemProps[]>>) => {
  var _items: PostItemProps[] = [];

  for (var i = 0; i < response.Items.length; i++) {
    var _item: { id: string } & PostItemProps = {
      id: response.Items[i]["ProviderID"],
      thumbnail: response.Items[i]["ImageUrl"],
      hasAuthor: response.Items[i].HasAuthor,
      title: response.Items[i]["Subject"] ?? "",
      location: response.Items[i]["ProviderDisplayName"] ?? "",
      link: response.Items[i]["DisplayUrl"],
      date: formatDate(response.Items[i]["Modified"]),
      provider: response.Items[i]["ProviderDisplayName"] ?? "",
      summary: response.Items[i]["Summary"] ?? "",
    };


    // Show nothing if the summary provides no extra insight
    if (_item.title.normalize() === _item.summary.normalize()) {
      _item.summary = "";
    }

    if (!items.map(s => s.id).includes(_item.id)) {
      _items.push(_item);
    }
  }

  setItems((items) => [...items, ..._items]);
  //setToSS(SS_ITEMS_KEY, [...items, ..._items]);
};

interface PostItemProps {
  thumbnail: string
  title: string
  location: string
  link: string
  date: string
  provider: string
  summary: string
  hasAuthor: boolean
}

// {thumbnail:string, title:any, location:string, link:string, activity:string, provider:string}
const PostItem = (props: PostItemProps) => {

  // Used for details flyout
  const [expanded, setExpanded] = useState(false);
  // Used for fading in thumbnails when they are loaded
  const [thumbLoaded, setThumbLoaded] = useState<boolean>(false);
  // Used for favorite button
  // const [liked, setLiked] = useState<boolean>(false); //TODO replace with backend call

  // Used for pop out of share button
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [copied, setCopied] = useState<boolean>(false);
  const handleCopy = () => {
    navigator.clipboard.writeText(props.link);
    setCopied(true);
  }
  const handleOpenShare = (e: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(e.currentTarget);
    setCopied(false);
  }

  // Collapse or expand description to properly use space
  useEventListener(window, 'resize', () => {
    if (window.innerWidth < 700 && expanded) {
      setExpanded(false)
    }
  });

  const hasContent = props.summary

  return (
    <Root data-file="CDS" data-method="PostItem">

      <Card variant="outlined" className={classes.container}>
        <CardHeader //TODO get avatar from backend
          avatar={
            <Avatar sx={{ bgcolor: red[500] }} aria-label="avatar">
              {props.provider.substring(0, 1).toUpperCase()}
            </Avatar>
          }
          title={props.provider}
          subheader={props.date}
        />
        <CardThumbnail
          className={classes.hover}
          loaded={thumbLoaded}
          onLoad={() => setThumbLoaded(true)}
          src={props.thumbnail}
          onClick={() => window.open(props.link, "_blank", "")}
        />
        <CardContent>
          <Typography variant="h6" color="text.secondary">
            {props.title}
          </Typography>
        </CardContent>
        <CardActions disableSpacing>
          <Share
            aria-label="share"
            anchorEl={anchorEl}
            onOpen={handleOpenShare}
            onClose={() => setAnchorEl(null)}
          >
            {!copied ? <div className={classes.share} onClick={handleCopy}>
              <IconButton aria-label='click to copy content'>
                <ContentCopyIcon />
              </IconButton>
              <Typography align='left' sx={{ p: 2, color: 'currentcolor' }}>Copy Link</Typography>
            </div>
              :
              <div className={`${classes.share} ${classes.sharePostCopy}`}>
                <CheckIcon sx={{ fill: 'white' }} />
                <Typography align='left' sx={{ p: 2 }}>Link Copied</Typography>
              </div>}

          </Share>
          {hasContent && <ExpandMore
            expand={expanded}
            onClick={() => setExpanded(!expanded)}
            aria-expanded={expanded}
            aria-label="show more"
          />}
        </CardActions>
        {hasContent && <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent>
            <Typography padding="0 10px" align="justify" variant="body1">
              {props.summary}
            </Typography>
            <div className={classes.blur} />
            <Typography sx={{ textAlign: "center", display: "block", cursor: "pointer" }} variant="button" onClick={() => window.open(props.link, "_blank", "")}>
              Click to read more
            </Typography>
          </CardContent>
        </Collapse>}
      </Card>
    </Root>
  );
}

/************************************************************************/
/************************* HELPER COMPONENTS ****************************/
/************************************************************************/
interface ExpandMoreProps extends IconButtonProps { expand: boolean; }
const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return (<IconButton {...other} >
    <ExpandMoreIcon />
  </IconButton>);
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto !important",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

interface ShareProps extends IconButtonProps { anchorEl: any, onOpen: any, onClose: any }

const Share = styled((props: ShareProps) => {
  const { anchorEl, onOpen, onClose, onClick, ...other } = props;
  return (<React.Fragment>
    <IconButton
      {...other} data-file="CDS"
      onClick={onOpen}>
      <ShareIcon />
    </IconButton>
    <Popover
      id={Boolean(anchorEl) ? 'share-popover' : undefined}
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
    >
      {props.children}
    </Popover>
  </React.Fragment>);
})(({ theme }) => ({

}));

interface ThumbnailProps extends React.ImgHTMLAttributes<HTMLImageElement> { loaded: boolean }
const CardThumbnail = styled((props: ThumbnailProps) => {
  const { loaded, ...other } = props;
  return (
    <LazyLoad once data-file="CDS" height={THUMB_HEIGHT} offset={800} placeholder={<Skeleton sx={{ height: "100%" }} animation="wave" variant="rectangular" />}>
      <img alt="author thumbnail" {...other} />
    </LazyLoad>
  )
})(({ theme, loaded }) => ({
  display: 'block',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  width: '100%',
  objectFit: 'contain',
  height: THUMB_HEIGHT,
  [theme.breakpoints.down('md')]: {
    height: '100%',
  },
  maxHeight: MAX_HEIGHT,
  opacity: !loaded ? 0 : 1,
  transition: theme.transitions.create('opacity', {
    duration: theme.transitions.duration.enteringScreen,
  }),
}));


export default Feed;
