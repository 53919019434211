import ApiRequestHandler from "./APIRequestHandler";
import NavigationService from "../services/Navigation";
import ApiConfig from "./ApiConfig.json"
import ProfileService from "../services/Profile";
import DcpsService from "../services/DCPS";
import defaultStore from "./Globale";

export const dcpsConfig = defaultStore.isProduction ? ApiConfig.dcpsProdConfig : ApiConfig.dcpsDevConfig;

const dcpsApiReqHandler = new ApiRequestHandler(dcpsConfig);
// const navigationApiConfig = 
export const navigationService = new NavigationService(dcpsApiReqHandler);

// const dcpsNavigationApiConfig = new ApiRequestHandler(dcpsConfig);
export const newNavigationService = new NavigationService(dcpsApiReqHandler);

// const profileApiConfig = new ApiRequestHandler(dcpsConfig);
export const profileService = new ProfileService(dcpsApiReqHandler);

// const dcpsProfileApiConfig = new ApiRequestHandler(dcpsConfig);
export const newProfileService = new ProfileService(dcpsApiReqHandler);

// const dcpsApiConfig = new ApiRequestHandler(jrapiConfig);
// export const dcpsService = new DcpsService(dcpsApiConfig);

// const newDcpsApiConfig = new ApiRequestHandler(dcpsConfig);
export const newDcpsService = new DcpsService(dcpsApiReqHandler);
