// singleton class
// https://stackoverflow.com/questions/30174078/how-to-define-singleton-in-typescript

// observable?

import { ActionItemResponse } from "../Schema";
import { newDcpsService } from "../util/Services";
import { RemoteDataWrapper } from "./WebWrapper";


export let actionItemRemote = new RemoteDataWrapper<ActionItemResponse[]>(undefined, () => newDcpsService.getUserActionItems());
