import { createSlice, PayloadAction } from "@reduxjs/toolkit";

// 2023-11-13 this is only being used for the following, but needs to be used to drive the main nav
// deciding if How To should be shown
// adjusting the left nav layout/css based on number of social items
// dynamic children extracted in links components:
//  - Blending Learning
//  - My Links
//  - How to

// potential prop ordering commented
export interface MenuItem {

  Id: string

  Acls: object[]
  Description: string
  DeepLink: string // falls back on the server to Name
  Enabled?: boolean
  HasUrlKey: boolean
  Icon: string
  Items?: MenuItem[]
  LinkedRoot?: boolean
  Name: string
  Parent: string
  Path: string
  Type: "Link" | "Folder"
  Url: string
  UrlKey?: string
  Weight?: number
}

export interface MenuState {
  menu: MenuItem[];
}
export function isMenuState(item: MenuState | any): item is MenuState {
  return (item as MenuState).menu !== undefined;
}

const initialState: MenuState = {
  menu: [],
};
const menuSlice = createSlice({
  name: "menu",
  initialState,
  reducers: {
    updateMenu(state, action: PayloadAction<MenuState>) {
      return Object.assign({}, state, {
        menu: action.payload.menu,
      });
    },
  },
});

export const menuActions = menuSlice.actions;

export default menuSlice;
