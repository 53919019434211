import {
    faTwitter,
    faFacebookF,
    faInstagramSquare,
    faLinkedin,
    faYoutube,
    faFlickr,
    fab
} from "@fortawesome/free-brands-svg-icons";

import { library } from '@fortawesome/fontawesome-svg-core'

// Add any used icons here so you can reference them as a string within <FontAwesomeIcon /> component
export default function initFontAwesome() {
    library.add(fab,
        faFacebookF,
        faTwitter,
        faInstagramSquare,
        faLinkedin,
        faYoutube,
        faFlickr)
}