import { Route, BrowserRouter, Routes } from "react-router-dom";

import Login from "../pages/Login/Login";

const UnauthenticatedAppRoot = () => {
  return (
    <BrowserRouter data-file="UnauthenticatedAppRoot">
      <main className={"mainDivRouter"}>
        <Routes>
          <Route path="*" element={<Login />} />
        </Routes>
      </main>
    </BrowserRouter>
  );
};

export default UnauthenticatedAppRoot;
