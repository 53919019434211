import { equalsAnyCI } from "../Reusable";
import { getFromLS } from "./storage";

let isProduction = equalsAnyCI(process.env.REACT_APP_ENV?.toString() ?? "", ["PRODUCTION", "PROD"]);
// HACK: don't push this to prod, diagnostic only
if (!isProduction) {
  console.log('globale!')
  console.log(process.env)
}
const useDynamicNav = getFromLS<boolean>("useDynamicNav") == true;
let defaultStore = {

  //DCPS
  webAPIEndPointUserProfileInfo: "/api/profile/myInfo",
  webAPIEndPointActionItems: "/api/DCPS/ActionItems",
  webAPIEndPointSchools: "/api/dcps/schools",
  webAPIEndPointWhoAmI: "/api/whoami",
  webAPIEndPointGetActivityFeed: "/api/dcps/contentStream",
  webAPIEndPointCharts: "/api/DCPS/Charts",

  //Navigation
  webAPIEndPointUserMenuItems: "/api/navigation/mainActionMenu",

  //Profile

  //Students.ts
  webAPIEndPointStudents: "/api/profile/students",
  webAPIEndPointStudentGrades: "/api/profile/studentGrades",
  webAPIEndPointStudentsPendingRequests: "/api/profile/requests",
  //PasswordCheck SecuritySettings.ts
  webAPIEndPointChangingPassword: "/api/profile/changePassword",
  webAPIEndPointPasswordPolicy: "/api/profile/passwordPolicy.html",
  webAPIEndPointCheckingPassword: "/api/profile/checkPassword",
  //UserProfile Update (ContactInfo.ts)
  webAPIEndPointUserProfile: "/api/profile/profile",
  //To get states for ContactForm USA states field drop down
  webAPIEndPointStates: "/api/profile/states",

  //Build and Environment-specific variables
  isProduction: isProduction,
  buildId: process.env?.REACT_APP_BUILD_ID ?? "X",
  buildNumber: process.env?.REACT_APP_BUILD_NUMBER ?? "X",
  buildDef: process.env?.REACT_APP_PIPELINE ?? "X",
  buildDefVersion: process.env?.REACT_APP_PIPELINE_V ?? "X",
  buildSourceVersion: process.env?.REACT_APP_SOURCE_V ?? "",

  // Figure out partner org loc
  pomAdmin: isProduction ? 'https://orgman.duvalschools.org/#/pomadmin' : 'https://white-cliff-00843c60f.1.azurestaticapps.net/#/pomadmin',
  ferpa: isProduction ? 'https://orgman.duvalschools.org/#/ferpa' : 'https://white-cliff-00843c60f.1.azurestaticapps.net/#/ferpa',
  useDynamicNav: useDynamicNav || false
};

export default defaultStore;
