import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface StudentInterface {
  CurrentGrades: any;
  DateOfBirth: string;
  DisplayName: string;
  FirstName: string;
  GradeLevel: string;
  Id: string;
  LastName: string;
  PhotoUrl: string;
  UserPrincipalName: string;
  extranetGUID: string;
  student_id: any;
}
interface StudentState {
  student: StudentInterface[];
}

const initialState: StudentState = {
  student: [],
};
const studentSlice = createSlice({
  name: "student",
  initialState,
  reducers: {
    updateStudent(state, action: PayloadAction<any>) {
      return Object.assign({}, state, {
        student: action.payload.student,
      });
    },
  },
});

export const studentActions = studentSlice.actions;

export default studentSlice;
