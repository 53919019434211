import { IApiRequestHandler } from "../util/APIRequestHandler";
import defaultStore from "../util/Globale";
import { addQueryParam, addQueryParams } from "../Reusable";
import * as ApiSchema from "../adapters/ApiSchema";
import { tryGetUpnOverride } from "./DCPS";


export let getIStudentsWithDevicesKeys = [
    "AssignedDevices", //Array
    "DateOfBirth", //String
    "DisplayName", //String
    "FirstName", //String
    "GradeLevel", //String
    "Id", //String
    "LastName", //String
    "PhotoUrl", //String
    "UserPrincipalName", //String
    "extranetGUID", //String
    "student_id", //Number
]


export default class ProfileService implements ApiSchema.IProfileService {
    private profileApiClient: IApiRequestHandler
    _upn: string | undefined = undefined;

    constructor(apiClient: IApiRequestHandler) {
        // console.log('initializing apiClient', apiClient);
        const overrideUpn = tryGetUpnOverride();
        if (!!overrideUpn) {
            this._upn = overrideUpn as string;
        }
        this.profileApiClient = apiClient;
    }

    // export const addQueryParams = (url: string, values: { key: string, value: string }[]) => {
    buildUrl(relPath: string, queryParams?: { key: string, value: string }[]): string {
        if (!!this._upn || (queryParams && queryParams.length > 0)) {
            var qp = !!this._upn ? [{ key: "upn", value: this._upn }, ... (queryParams ?? [])] : queryParams ?? [];
            return addQueryParams(this.profileApiClient.getHostName() + relPath, qp);
        }

        return this.profileApiClient.getHostName() + relPath;
    }

    async postEmailRename(email: string): Promise<unknown> {
        let url = this.buildUrl("/api/Profile/profile");

        let emailObj = JSON.stringify({
            Email: email,
        });
        return await this.profileApiClient.updateMethod(url, { method: 'PATCH', data: emailObj });
    }

    async verifyAvailableEmail(email: string): Promise<ApiSchema.VerifyAvailableEmailResponse | ApiSchema.ServerErrorResponse> {
        // https://dcps-core-api-dev.azurewebsites.net/api/Profile/checkEmail?email=chswin@hotmail.com
        let url = this.buildUrl("/api/Profile/checkEmail");
        let full = addQueryParam(url, "email", email);

        return await this.profileApiClient.getOrDelete(full, {});
    }

    async saveNewPassword(currentPassword: string, newPassword: string): Promise<ApiSchema.JustMessage | ApiSchema.ServerErrorResponse> {
        console.log(currentPassword, newPassword);
        let passInfo = JSON.stringify({
            NewPassword: newPassword,
            OldPassword: currentPassword,
        });

        let url = this.buildUrl(defaultStore.webAPIEndPointChangingPassword);

        return await this.profileApiClient.updateMethod(url, { data: passInfo });
    }

    async getPasswordPolicy(): Promise<string | ApiSchema.ServerErrorResponse> {
        let url = this.buildUrl(defaultStore.webAPIEndPointPasswordPolicy);

        return await this.profileApiClient.getOrDelete(url, {});
    }

    async postCheckPassword(passwordEntered: string): Promise<ApiSchema.CheckPasswordResponse | ApiSchema.ServerErrorResponse> {
        let url = this.buildUrl(defaultStore.webAPIEndPointCheckingPassword);

        let passEntered = JSON.stringify({
            Password: passwordEntered,
        });

        return await this.profileApiClient.updateMethod(url, { data: passEntered });
    }

    async getContactInfo(): Promise<ApiSchema.UserContactInfo | ApiSchema.ServerErrorResponse> {
        let url = this.buildUrl(defaultStore.webAPIEndPointUserProfile);

        return await this.profileApiClient.getOrDelete(url, {});
    }

    async postContactInfo(userData: Partial<ApiSchema.UserContactInfo>): Promise<ApiSchema.UserContactInfo | ApiSchema.ServerErrorResponse> {
        let url = this.buildUrl(defaultStore.webAPIEndPointUserProfile);
        let contactInfo = JSON.stringify(userData);

        return await this.profileApiClient.updateMethod(url, { data: contactInfo });
    }

    async getStatesInfo(): Promise<ApiSchema.StateInfo[] | ApiSchema.ServerErrorResponse> {
        let url = this.buildUrl(defaultStore.webAPIEndPointStates);

        return await this.profileApiClient.getOrDelete(url, {});
    }

    async getPhoneHelp(): Promise<string | ApiSchema.ServerErrorResponse> {
        let url = this.buildUrl("/api/profile/phoneHelp.html");

        return await this.profileApiClient.getOrDelete(url, {});
    }

    // getMyDevices(): Promise<IDevice[] | ErrorInterface>
    async getMyDevices(): Promise<ApiSchema.IDevice[] | ApiSchema.ServerErrorResponse> {
        let url = this.buildUrl("/api/profile/myDevices");

        return await this.profileApiClient.getOrDelete(url, {});
    }

    async getDeviceLogs(logUrl: string): Promise<ApiSchema.DeviceLog[] | ApiSchema.ServerErrorResponse> {
        let url = logUrl;

        return await this.profileApiClient.getOrDelete(url, {});
    }

    async verifyPhoneNumber(): Promise<ApiSchema.VerifyPhoneNumberResponse | ApiSchema.ServerErrorResponse> {
        let url = this.buildUrl("/api/profile/verifyMobilePhone");

        return await this.profileApiClient.getOrDelete(url, {});
    }

    async postVerifyPhoneNumber(id: string, code: string): Promise<ApiSchema.JustMessage | ApiSchema.ServerErrorResponse> {
        let url = this.buildUrl("/api/profile/verifyMobilePhone");

        let contactInfo = JSON.stringify({
            ResponseId: id,
            Code: code,
        });

        return await this.profileApiClient.updateMethod(url, { data: contactInfo });
    }

    async getStudentInfo(): Promise<ApiSchema.StudentInfo[] | ApiSchema.ServerErrorResponse> {
        let url = this.buildUrl("/api/profile/myStudentInfo");
        return await this.profileApiClient.getOrDelete(url, {});
    }

    async getStudentContactInfo(studentId: string): Promise<ApiSchema.StudentContactInfo[] | ApiSchema.ServerErrorResponse> {
        let url = this.buildUrl("/api/profile/myStudentContactInfo?student_id=" + encodeURIComponent(studentId));

        return await this.profileApiClient.getOrDelete(url, {});
    }

    async getMyStudentDevices(): Promise<ApiSchema.IStudentWithDevices[] | ApiSchema.ServerErrorResponse> {// need to account for url having a query already in it
        let url = this.buildUrl("/api/profile/myStudentDevices");
        console.log("student URL", url);
        return await this.profileApiClient.getOrDelete(url, {});
    }

    async getStudentAssignments(stuId: string, courseId: string): Promise<ApiSchema.StudentAssignment[] | ApiSchema.ServerErrorResponse> {
        // HACK: not encoding properly
        let url = this.buildUrl("/api/profile/studentAssignments?student_id=" + stuId + "&course_period_id=" + courseId);

        return await this.profileApiClient.getOrDelete(url, {});
    }

    async getStudentAttendance(periodId: any, stuId: any): Promise<ApiSchema.StudentAttendance[] | ApiSchema.ServerErrorResponse> {
        // HACK: not encoding properly
        let url = this.buildUrl("/api/profile/studentAttendance?period_id=" + periodId + "&student_id=" + stuId);

        return await this.profileApiClient.getOrDelete(url, {});
    }

    async getStudents(): Promise<ApiSchema.Student[] | ApiSchema.ServerErrorResponse> {
        let url = this.buildUrl(defaultStore.webAPIEndPointStudents);

        return await this.profileApiClient.getOrDelete(url, {});
    }

    async getStudentGrades(): Promise<unknown | ApiSchema.ServerErrorResponse> {
        let url = this.buildUrl(defaultStore.webAPIEndPointStudentGrades);

        const results = await this.profileApiClient.getOrDelete(url, {});

        return results;
    }

    async getStudentAddRequests(): Promise<unknown[] | ApiSchema.ServerErrorResponse> {
        let url = this.buildUrl(defaultStore.webAPIEndPointStudentsPendingRequests);

        return await this.profileApiClient.getOrDelete(url, {});
    }

    async postStudents(studentIDPassed: string, studentDOBPassed: string, studentLastName: string): Promise<ApiSchema.JustMessage | ApiSchema.ServerErrorResponse> {
        console.log(studentIDPassed, studentDOBPassed);

        var studentInfo = JSON.stringify({
            Id: studentIDPassed,
            DateOfBirth: studentDOBPassed,
            LastName: studentLastName,
        });

        console.log(studentInfo);

        let url = this.buildUrl(defaultStore.webAPIEndPointStudents);

        return await this.profileApiClient.updateMethod(url, { data: studentInfo })
    }

    async deleteStudents(stuId: string): Promise<ApiSchema.JustMessage | ApiSchema.ServerErrorResponse> {
        console.log("Deleting org : " + stuId);

        let url = this.buildUrl(defaultStore.webAPIEndPointStudents + "?id=" + stuId);

        return await this.profileApiClient.getOrDelete(url, { method: "DELETE" });
    }

    async getUserSchools(): Promise<ApiSchema.School[] | ApiSchema.ServerErrorResponse> {
        let url = this.buildUrl("/api/profile/mySchools");

        return await this.profileApiClient.getOrDelete(url, {});
    }

    async get365Apps(): Promise<ApiSchema.O365AppResponse | ApiSchema.ServerErrorResponse> {
        let url = this.buildUrl("/api/profile/myApps");

        return await this.profileApiClient.getOrDelete(url, {});
    }

    async getUserProfileInfo(): Promise<ApiSchema.UserInterface | ApiSchema.ServerErrorResponse> {
        let url = this.buildUrl(defaultStore.webAPIEndPointUserProfileInfo);
        // console.log("Profile.getUserProfileInfo");

        return await this.profileApiClient.getOrDelete(url, {});
    }
}
