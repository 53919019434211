import React from 'react';
//Business
import oneViewWhite from './brand/OneviewWhite.webp'
import oneViewFull from './brand/Oneview Logo New.webp'
import oneViewClean from './brand/OneViewClean.webp'
import headerLogo from "./brand/sitelogoDCPSsm.webp";
import homeroom from "./brand/dcps_wide.webp";

// Links
import bookings from './links/Bookings_128x128.webp'
import canva from './links/Canva_128x128.webp'
import excel from './links/Excel_128x128.webp'
import forms from './links/Forms_128x128.webp'
import lists from './links/Microsoft_Lists_128x128.webp'
import teams from './links/Microsoft_Teams_128x128.webp'
import todo from './links/Microsoft_To_Do_128x128.webp'
import stream from './links/Microsoft-Stream_128x128.webp'
import office from './links/Office_128x128.webp'
import oneDrive from './links/OneDrive_128x128.webp'
import oneNote from './links/OneNote_128x128.webp'
import outlook from './links/Outlook_128x128.webp'
import planner from './links/Planner_128x128.webp'
import powerpoint from './links/PowerPoint_128x128.webp'
import sharepoint from './links/SharePoint_128x128.webp'
import sway from './links/Sway_128x128.webp'
import whiteboard from './links/Whiteboard_128x128.webp'
import word from './links/Word_128x128.webp'
import flip from './links/flip.webp'

// Business
export const OneViewWhite = (props: any) => (<img src={oneViewWhite} alt="OneView logo" {...props} />)
export const OneViewFull = (props: any) => (<img src={oneViewFull} alt="OneView logo" {...props} />)
export const OneViewClean = (props: any) => (<img src={oneViewClean} alt="OneView logo" {...props} />)
export const Favicon = (props: any) => (<img src={headerLogo} alt="OneView logo" {...props} />)
export const HomeRoom = (props: any) => (<img src={homeroom} alt="homeroom logo" {...props} />)


// Links
export const Bookings = (props: any) => (<img src={bookings} alt="bookings" {...props} />)
export const Canva = (props: any) => (<img src={canva} alt="canva"{...props} />)
export const Excel = (props: any) => (<img src={excel} alt="excel" {...props} />)
export const Forms = (props: any) => (<img src={forms} alt="forms" {...props} />)
export const Lists = (props: any) => (<img src={lists} alt="lists" {...props} />)
export const Teams = (props: any) => (<img src={teams} alt="teams"{...props} />)
export const ToDo = (props: any) => (<img src={todo} alt="todo" {...props} />)
export const Stream = (props: any) => (<img src={stream} alt="stream" {...props} />)
export const Office = (props: any) => (<img src={office} alt="office" {...props} />)
export const OneDrive = (props: any) => (<img src={oneDrive} alt="oneDrive" {...props} />)
export const OneNote = (props: any) => (<img src={oneNote} alt="oneNote" {...props} />)
export const Outlook = (props: any) => (<img src={outlook} alt="outlook" {...props} />)
export const Planner = (props: any) => (<img src={planner} alt="planner" {...props} />)
export const PowerPoint = (props: any) => (<img src={powerpoint} alt="powerpoint" {...props} />)
export const SharePoint = (props: any) => (<img src={sharepoint} alt="sharepoint" {...props} />)
export const Sway = (props: any) => (<img src={sway} alt="sway" {...props} />)
export const Whiteboard = (props: any) => (<img src={whiteboard} alt="whiteboard" {...props} />)
export const Word = (props: any) => (<img src={word} alt="word" {...props} />)
export const Flip = (props: any) => (<img src={flip} alt="word" {...props} />)

// via api or api-returned link
export const ApiImg = (props: { baseUrl: string, url: string } & any) => {
    const { url, baseUrl, ...props2 } = props;
    // would be nice if this automatically accounted for baseUrl ending in / and/or url starting with it
    return (<img src={props.baseUrl + props.url} {...props2} />);
}